button {
	padding: 1rem;
	display: flex;
	color: var(--white);
	align-items: center;
	border-radius: 10px;
	font-weight: lighter;
	justify-content: center;
	font-size: var(--fs-text);
	background-color: #ffa103;
}

.black {
	color: black;
}

.enroll-btn {
	background-color: #1e1e1e;
	padding: 1rem 2rem;
	font-size: var(--fs-normal);
}

.enroll-btn-lg {
	background-color: #ffa103;
	padding: 1rem 2rem;
	font-size: var(--fs-title2);
	margin: auto;
	margin-top: 1rem;
}

.center {
	margin: 1rem auto;
}

.roles-btn {
	font-size: var(--fs-normal);
	padding: 1rem 2rem;
	margin-right: auto;
}

.notActive {
	background-color: white;
	color: #6f6c90;
	border: 2px solid;
}

.hackaThon {
	background-color: transparent;
	border: 1px solid var(--brand-color);
	border-radius: 1rem;
}

.active {
	background-color: var(--brand-color) !important;
}

.alertBtn {
	color: white;
	padding: 0.8rem 1.4rem;
	border-radius: 1.2rem;
	background-color: #ffa103;
}
