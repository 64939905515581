.header {
  background-color: white;
  padding: 1rem 1rem;
  position: fixed;
  width: 100%;
  z-index: 150;
  box-shadow: 0 0 1rem rgba(8, 16, 16, 0.15);

  @media screen and (min-width: 641px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: 1025px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media screen and (min-width: 1225px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  @media screen and (min-width: 1600px) {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  main {
    display: flex;
    align-items: center;
    overflow: visible;

    & > * {
      flex: 1;
    }
  }
}

.headerBanner {
  padding-top: 60px;
  .couponBanner {
    background-color: #d4ab0a;
    height: 50px;
    position: absolute;
    top: 0;
    width: 100vw;
    left: 0;
    color: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: ;
    overflow: hidden;
    p {
      text-align: center;
      b {
        font-weight: 600;
      }
    }
    svg {
      width: 40px;
      height: 50px;
      padding: 0 5px;
    }
    .right {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.left-side {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-start;

  span {
    width: auto !important;
    margin-left: 0rem !important;
  }

  img {
    height: 38px;
    width: auto;
    cursor: pointer;

    @media screen and (max-width: 450px) {
      height: 35px;
    }

    @media screen and (max-width: 400px) {
      height: 30px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-left: 4px;
  }

  h1 {
    font-size: 12px;
    color: #363b43;
    font-weight: 550;
    // letter-spacing: 2px;
    font-family: inherit;
    display: flex;
    align-items: center;

    @media screen and (min-width: 400px) {
      font-size: 18px;
    }
    @media screen and (min-width: 481px) {
      font-size: 20px;
    }

    span {
      margin: 0rem;
      font-size: inherit;
      font-weight: 550;
      color: #f3932d;
      // letter-spacing: 2px;
      line-height: normal;
    }
  }
  p {
    font-size: 9px;
    margin-left: 3px;

    @media screen and (min-width: 400px) {
      font-size: 10px;
    }
  }
}

.menu {
  display: none;

  @media screen and (min-width: 800px) {
    display: flex;
  }
}

.middle-side {
  align-items: center;
  flex: 1;
  gap: 2rem;
  display: none;
  justify-content: flex-end;
  a {
    font-size: var(--fs-text);
    font-weight: var(--fw-medium);
    color: var(--fc-para);
    cursor: pointer;
    display: block;
    opacity: 0.9;
    transition: 0.15s ease;
    white-space: nowrap;

    &:hover {
      color: var(--brand-color);
      opacity: 1;
    }

    &[class~="active"] {
      color: var(--brand-color);
      transform: scale(1.15);
      opacity: 1;

      @media screen and (max-width: 800px) {
        transform-origin: left center;
      }
    }
  }

  &.mob {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 18px;
    border-radius: calc(var(--radius) * 1.2);
    background-color: white;
    width: calc(100vw - var(--gap-side));
    @media screen and (min-width: 641px) {
      width: calc(100vw - 3rem);
    }
    transition: 0.3s ease;
    box-shadow: 0 0 1rem rgba(8, 16, 16, 0.25);
    clip-path: circle(0% at top right);
    z-index: 2;

    & > div {
      padding: 25px 20px;
      display: flex;
      gap: 18px;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &[data-active="true"] {
    &.mob {
      clip-path: circle(100%);

      & > div {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 641px) {
    &.mob {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: 800px) {
    display: flex;
  }
}

.mob-nav {
  align-items: center;
  justify-content: flex-end;
  display: flex;

  @media screen and (min-width: 800px) {
    display: none;
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 40px;
  cursor: pointer;

  svg {
    height: 100%;
    width: auto;
    color: var(--fc-para);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease;
    opacity: 0;
  }

  &[data-active="true"] {
    .closed {
      opacity: 1;
      height: 38px;

      @media screen and (min-width: 400px) {
        height: 41.5px;
      }

      @media screen and (min-width: 450px) {
        height: 48px;
      }
    }
  }

  &[data-active="false"] {
    .opened {
      opacity: 1;
      height: 30px;
      width: 30px;
    }
  }

  @media screen and (min-width: 400px) {
    height: 38px;
    width: 38px;
  }
}
