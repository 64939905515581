.background {
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 65px);
  background-color: rgba(0, 0, 0, 0.3);

  // @media screen and (min-width: 941px) {
  height: 100vh;
  // }
}

.modalContainer {
  padding: 10px;
  max-width: 90vw;
  width: 100%;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: var(--shadow2);
  max-height: calc(100% - 40px);
  background-color: var(--second-white);
  border: 1px solid;

  @media screen and (min-width: 641px) {
    padding: 20px;
    max-width: 70vw;
    border-radius: 15px;
  }
  @media screen and (min-width: 1050px) {
    max-width: 40vw;
  }
  @media screen and (min-width: 1500px) {
    max-width: 35vw;
  }
}

.header {
  height: 40px;
  display: grid;
  align-content: center;
  margin-bottom: var(--gap-md);
  grid-template-columns: auto 40px;
}

#header-fiexd {
  top: 0;
  z-index: 300;
  padding: 10px 0;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  background-color: var(--second-white);
}

.cross-left {
  padding: 0;
  width: 40px;
  z-index: 100;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: inherit;
}
#cross-left-icon {
  width: 50%;
  height: 50%;
  color: black;
}
.cross-left:hover {
  border-radius: 50%;
  background-color: #dddddd;
}

.heading {
  display: flex;
  // padding: 0 10px;
  align-items: center;
  /* position: fixed; */
}
.heading h2 {
  font-size: var(--fs-xl);
  color: var(--text-color2);
  font-weight: var(--fw-medium);
}
.modal-content {
  width: 100%;
  height: 100%;

  overflow-y: scroll;
}

@media only screen and (max-width: 640px) {
  .modalContainer {
    max-height: 100vh;
    padding-top: 0;
    animation-duration: 0.5s;
    animation-name: modalAnimation;
  }

  @keyframes modalAnimation {
    from {
      opacity: 0.7;
      transform: translateY(-200px);
    }

    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.linkedInModal {
  z-index: 10000;
  max-height: calc(100vh - 115px);
}
