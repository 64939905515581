.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  z-index: 99;
  height: 100%;
  gap: var(--padding);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white-shade);
}

.content {
  text-align: center;
  height: 18rem;
  width: 18rem;

  @media screen and (max-width: 1400px) {
    height: 16rem;
    width: 16rem;
  }

  @media screen and (max-width: 600px) {
    height: 12rem;
    width: 12rem;
  }
}

.CircleCont {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ImageCont {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
